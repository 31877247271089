import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Parallax } from 'react-scroll-parallax';
import Typed from 'react-typed';

const Home = () => {

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "home.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                mobil: file(relativePath: { eq: "home-mobile.png" }) {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                arrowDown: file(relativePath: { eq: "arrow-down.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        `
    )

    const imageData = data.desktop.childImageSharp.fluid
    const arrowDown = data.arrowDown.childImageSharp.fixed

    const sources = [
        data.mobil.childImageSharp.fluid,
        {
            ...data.desktop.childImageSharp.fluid,
            media: `(min-width: 491px)`,
        },
    ]

    return (<section className="w-full top-0 z-30 block relative" id="home">
        <div className="overflow-hidden">
            <Parallax y={[-40, 40]} tagOuter="figure">
                <BackgroundImage className="w-full" fluid={sources}>
                    <div className="flex lg:px-20 px-4 h-screen top-0 items-center"  style={{minHeight: "800px"}}>
                        <div>
                            <h3 className="text-white lg:text-4xl text-2xl font-raleway uppercase font-bold">EN BANSō TE AYUDAMOS A</h3>
                            <Typed
                                loop
                                className="text-white lg:text-5xl text-4xl font-raleway font-black"
                                strings={[
                                    `Incrementar tu rentabilidad`,
                                    `Acelerar tu crecimiento`,
                            `Maximizar tus recursos`,
                            `Mitigar riesgos`,
                            `Obtener capital`
                            ]}
backSpeed={80}
                                typeSpeed={40}
                            />
                            <h4 className="text-white lg:text-2xl text-xl font-raleway pt-14 font-medium">Te brindamos acompañamiento integral financiero y fiscal para <br/> el crecimiento de tu empresa. </h4>
                            <AnchorLink stripHash to="/#nosotros" title="Nosotros" className="mb-0 absolute bottom-0 block"><Img fixed={arrowDown} alt="Nosotros"/></AnchorLink>
                        </div>
                    </div>
                </BackgroundImage>
            </Parallax>
        </div>
    </section>);
};

export default Home;
