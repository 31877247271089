import React, {useState} from "react"
import BackgroundImage from "gatsby-background-image";

const Slider = (props) => {

    const [active, setActive] = useState(0)

    const slides = props.slides.map((slide, index) => (index === active ? <BackgroundImage key={index} fluid={slide.image} className={`flex w-full py-32 justify-center items-center text-center`}><div className="lg:w-1/2 px-4 lg:px-0 mx-auto block relative"><p className="font-lato text-yellow leading-relaxed text-lg lg:text-xl" dangerouslySetInnerHTML={slide.description}/><p className="text-white font-lato mt-10 font-black text-md">{slide.author}</p></div></BackgroundImage> : <div key={index} className="hidden"/>))

    const bullets = props.slides.map((bullet, index) => (<li key={index} className={`rounded-full w-2 h-2 mx-1 border-2 border-white cursor-pointer ${active === index ? 'bg-yellow border-yellow' : ''}`} onClick={(event) => { event.preventDefault();  setSlide(index) }} />))

    const setSlide = (toActive) => { setActive(toActive) }

    return (
        <div className="w-full block relative">
            {slides}
            <div className="absolute w-full bottom-0 mb-10">
                <ul className="flex justify-center mx-auto items-center min-w-1/3">{bullets}</ul>
            </div>
        </div>
    );
}


export default Slider;
