import React, {useState} from "react"
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import Slider from "../slider";
import SiteTitle from "../SiteTitle";
import SiteSubtitle from "../SiteSubtitle";

const Equipo = () => {

    const [showMembers, setShowMembers] = useState([false, false, false]);

    const data = useStaticQuery(
        graphql`
            query {
                paulina: file(relativePath: { eq: "paulina-corro.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                alejandra: file(relativePath: { eq: "alejandra-ganoa.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                hiram: file(relativePath: { eq: "hiram-valdez.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                greengold: file(relativePath: { eq: "green-gold-farms.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                pulpoescarlata: file(relativePath: { eq: "pulpo-escafandra.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                puntocrea: file(relativePath: { eq: "punto-crea.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                rocher: file(relativePath: { eq: "rocher.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                sanjose: file(relativePath: { eq: "san-jose-cremeria.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                visadelnorte: file(relativePath: { eq: "visa-del-norte.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                banner1: file(relativePath: { eq: "banner1.jpg" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                banner2: file(relativePath: { eq: "banner2.jpg" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                toggle: file(relativePath: {eq: "toggle.png"}) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                linkedin: file(relativePath: {eq: "linkedin-yellow.png"}) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }`)

    const handleToggleMember = (member) => {
        const members = [...showMembers];
        members[member] = !members[member];
        setShowMembers(members);
    };

    const paulina = data.paulina.childImageSharp.fluid
    const alejandra = data.alejandra.childImageSharp.fluid
    const hiram = data.hiram.childImageSharp.fluid
    const greenGold = data.greengold.childImageSharp.fluid
    const pulpoescarlata = data.pulpoescarlata.childImageSharp.fluid
    const puntocrea = data.puntocrea.childImageSharp.fluid
    const rocher = data.rocher.childImageSharp.fluid
    const sanjose = data.sanjose.childImageSharp.fluid
    const visadelnorte = data.visadelnorte.childImageSharp.fluid
    const banner1 = data.banner1.childImageSharp.fluid
    const banner2 = data.banner2.childImageSharp.fluid
    const toggle = data.toggle.childImageSharp.fluid
    const linkedin = data.linkedin.childImageSharp.fluid

    const slides = [
        {
            image: banner2,
            description: { __html: `"Nuestra idea de negocio era compleja, pero pudimos aterrizarla gracias a su labor en un plan de negocios. <br/><br/> Dentro de la pandemia, nos brindaron información útil, clara y estratégica gracias a la cual pudimos adaptarnos al mercado y buscar oportunidades en circunstancias adversas".</span>` },
            author: 'Equipo Punto Crea'
        },
        {
            image: banner1,
            description: { __html: `<span>"Con el apoyo de Bansó hemos conseguido un mayor entendimiento de nuestras finanzas y contabilidad. Su acompañamiento nos ha dado una visión acerca de nuestra estrategia de negocio y su colaboración ha sido esencial para la creación de nuestro modelo financiero. <br/><br/> Ahora tenemos horizonte hacia la búsqueda de capital y crecimiento de nuestro negocio".</span>`},
            author: 'Visa del Norte Esmeralda'
        }]

    return (<section id="equipo">
        <div className="lg:px-20 px-4 pt-20 pb-10">
            <SiteTitle title="Nuestro Equipo"/>
            <SiteSubtitle subtitle="Somos profesionales de la consultoría"/>
            <div className="lg:flex justify-around flex-start mt-10 lg:mt-20 w-full">
                <div className="lg:w-1/4 flex flex-col justify-between mt-10 lg:mt-0" data-sal="slide-up" data-sal-delay="700" data-sal-easing="easeInOutSine">
                    <div>
                        <Img fluid={paulina} className="w-full" alt="Paulina Corro" />
                        <div className="w-full h-1/2">
                            <div className="flex justify-start items-center">
                                <h3 className="text-blue capitalize font-bold text-xl mt-4 font-raleway">Paulina Corro</h3>
                                <a className="cursor-pointer" onClick={() => handleToggleMember(0)}><Img className={`${showMembers[0] ? '' : 'transform -rotate-90'} w-6 mt-4 ml-2`} fluid={toggle} alt='toggle-arrow' /></a>
                            </div>
                            {(showMembers[0]) ? (<>
                                <p className="font-lato text-gray mt-4 text-justify">Paulina es socia fundadora de Bansó. Trabajó en el sector financiero por 10 años colaborando
                                con firmas de consultoría globales como EY &amp; Duff &amp; Phelps, así como en el fondo de capital
                                privado: Gerbera Capital.</p>

                                <p className="font-lato text-gray mt-4 text-justify">Durante este periodo participó en equipos multidisciplinarios enfocados en proyectos de
                                infraestructura, O&amp;G, así como capital de riesgo y capital privado.</p>

                                <p className="font-lato text-gray mt-4 text-justify">Es licenciada en Dirección Financiera por el Instituto Tecnológico Autónomo de México (ITAM) y
                                cuenta con el primer nivel de CFA.</p></>) : (<p/>)
                            }

                        </div>
                    </div>
                    {(showMembers[0]) ? (<a href="https://www.linkedin.com/in/paulinacorro/" target="_blank" className="text-yellow linkedin font-bold font-raleway bottom-0 relative"><Img className="w-8" fluid={linkedin}/></a>) : (<span/>) }
                </div>
                <div className="lg:w-1/4 flex flex-col justify-between mt-10 lg:mt-0" data-sal="slide-up" data-sal-delay="700" data-sal-easing="easeInOutSine">
                    <div>
                        <Img fluid={alejandra} className="w-full" alt="Alejandra Gaona" />
                        <div className="w-full h-1/2">
                            <div className="flex justify-start items-center">
                                <h3 className="text-blue capitalize font-bold text-xl mt-4 font-raleway">Alejandra Gaona</h3>
                                <a className="cursor-pointer" onClick={() => handleToggleMember(1)}><Img className={`${showMembers[1] ? '' : 'transform -rotate-90'} w-6 mt-4 ml-2`} fluid={toggle} alt='toggle-arrow' /></a>
                            </div>
                            {(showMembers[1]) ? (<>
                                <p className="font-lato text-gray mt-4 text-justify">Alejandra es socia fundadora de Bansó con más de 20 años de experiencia en auditoría externa,
                                contraloría y finanzas. Ha formado parte de reconocidas empresas como PwC, Grupo Lala y
                                    Gerbera Capital.</p>

                                <p className="font-lato text-gray mt-4 text-justify">Es Licenciada en Contaduría por la Universidad Nacional Autónoma de México (UNAM) y cuenta
                                    con una especialidad en Finanzas por la Universidad Panamericana.</p>
                            </>) : (<p/>)}
                        </div>
                    </div>
                    {(showMembers[1]) ? (<a href="https://www.linkedin.com/in/alejandra-gaona-1886b9204/" target="_blank" className="text-yellow font-bold font-raleway bottom-0 relative"><Img className="w-8" fluid={linkedin}/></a>) : (<span/>) }
                </div>
                <div className="lg:w-1/4 flex flex-col justify-between mt-10 lg:mt-0" data-sal="slide-up" data-sal-delay="700" data-sal-easing="easeInOutSine">
                    <div  className="h-1/2">
                        <Img fluid={hiram} className="w-full" alt="Hiram Valdez" />
                        <div className="w-full" >
                            <div className="flex justify-start items-center">
                                <h3 className="text-blue capitalize font-bold text-xl mt-4 font-raleway">Hiram Valdez</h3>
                                <a className="cursor-pointer" onClick={() => handleToggleMember(2)}><Img className={`${showMembers[2] ? '' : 'transform -rotate-90'} w-6 mt-4 ml-2`} fluid={toggle} alt='toggle-arrow' /></a>
                            </div>
                            {(showMembers[2]) ? (<>
                                <p className="font-lato text-gray mt-4 text-justify">Hiram Valdez cuenta con 12 años de experiencia como banquero de inversión, emprendedor y gerente de finanzas.</p>
                                <p className="font-lato text-gray mt-4 text-justify">Ha trabajado para el IFC, brazo privado del Banco Mundial, y plataformas tecnológicas como Uber con funciones globales.</p>
                                <p className="font-lato text-gray mt-4 text-justify">Cuenta con experiencia en el sector Fintech así como eCommerce, Mobilidad, Petroquímico e Inmobiliario, ejecutando transacciones de capital privado, project-finance y financiamiento   senior.</p>
                            </>) : (<p/>)}
                        </div>
                    </div>
                    {(showMembers[2]) ? (<a href="https://www.linkedin.com/in/hiramvaldez/" target="_blank" className="text-yellow font-bold font-raleway bottom-0 relative"><Img className="w-8" fluid={linkedin}/></a>) : (<span/>) }
                </div>
            </div>
        </div>
        <div className="bg-yellow-300 lg:pb-20 py-4">
            <div className="text-center lg:py-10">
                <SiteTitle title="Nuestros Clientes" color="text-blue"/>
            </div>
                <div className="lg:flex lg:justify-around lg:items-center px-10 lg:px-20 grid grid-cols-3 gap-4">
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={greenGold}/></div>
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={puntocrea}/></div>
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={pulpoescarlata}/></div>
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={rocher}/></div>
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={sanjose}/></div>
                    <div className="w-full flex items-center md:w-40 md:ml-6 lg:w-44 "><Img className="lg:flex-1 md:flex-1 mx-auto scale w-full " fluid={visadelnorte}/></div>
                </div>
        </div>
        <Slider slides={slides}/>
    </section>);
};

export default Equipo;
