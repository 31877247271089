import React from "react"
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import {Parallax} from "react-scroll-parallax";

const Servicios = () => {

    const data = useStaticQuery(
        graphql`
            query {
                bannerMobile: file(relativePath: { eq: "nosotros-banner-mobil.jpg" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                bannerDesktop: file(relativePath: { eq: "nosotros-banner.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                capitalSemilla: file(relativePath: { eq: "capital-semilla-icon.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                capitalRiesgo: file(relativePath: { eq: "capital-riesgo-icon.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                acompanamientoFinanciero: file(relativePath: { eq: "financiero-icon.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                contableFiscal: file(relativePath: { eq: "contable-fiscal-icon.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                nosotrosIcon: file(relativePath: { eq: "nosotros-icon.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                greenArrow: file(relativePath: { eq: "green-arrow.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 39) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                yellowArrow: file(relativePath: { eq: "yellow-arrow.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 39) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                bansoIcon: file(relativePath: { eq: "banso-icon.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                desicionManager: file(relativePath: { eq: "decision-manager.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                loga: file(relativePath: { eq: "loga.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        `
    )

    const capitalSemilla = data.capitalSemilla.childImageSharp.fluid
    const capitalRiesgo = data.capitalRiesgo.childImageSharp.fluid
    const acompanamientoFinanciero = data.acompanamientoFinanciero.childImageSharp.fixed
    const nosotrosIcon = data.nosotrosIcon.childImageSharp.fixed
    const contableFiscal = data.contableFiscal.childImageSharp.fixed
    const bansoIcon = data.bansoIcon.childImageSharp.fixed

    const sources = [
        data.bannerMobile.childImageSharp.fluid,
        {
            ...data.bannerDesktop.childImageSharp.fluid,
            media: `(min-width: 491px)`,
        },
    ]

    return (<section>
        <div className="overflow-hidden">
            <Parallax y={[-40, 40]} tagOuter="figure">
                <BackgroundImage fluid={sources} className="items-center flex shadow-lg flex-col py-64">
                    <h2 className="text-white font-bold lg:text-5xl text-2xl font-raleway text-center">Maximiza el potencial <br/></h2>
                    <h2 className="text-white font-normal lg:text-5xl text-2xl pb-10 font-raleway text-center mt-4">de tu empresa</h2>
                    <Img className='my-4 mb-10' fixed={nosotrosIcon}/>
                    <h3 className="text-white lg:text-5xl text-2xl uppercase font-raleway font-bold">Nuestros Servicios</h3>
                </BackgroundImage>
            </Parallax>
        </div>

        <div className="bg-gray-100" id="servicios">
            <div className="lg:flex justify-between lg:px-20 px-4 over-position">
                <article data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" className="px-8 py-8 bg-white lg:w-1/3 lg:mr-4 flex shadow-lg flex-col mb-10 lg:mb-0 justify-between">
                    <div>
                        <Img className="w-20" fluid={capitalSemilla} alt="Capital Semilla"/>
                        <h3 className=" mt-2 text-blue font-raleway font-bold text-xl">Capital Semilla</h3>
                        <p className="text-gray mt-4 font-lato">Haz de tu idea una realidad.</p>
                        <p className="text-gray mt-4 font-lato text-yellow font-bold">¿Tienes una idea innovadora pero no tienes claridad para desarrollarla y buscar los recursos necesarios para concretarla?</p>
                        <p className="text-gray mt-4 font-lato">En Bansó te apoyamos desde el desarrollo del plan de negocios y te acompañamos en todo el proceso de financiamiento.</p>
                        <p className="text-gray mt-4 font-lato">Te ayudamos a desarrollar un modelo financiero para que tengas claridad en tu modelo de negocio, así como en la elaboración de tu pitch deck y prospección de inversionistas.</p>
                    </div>
                    <AnchorLink to="/#contacto" stripHash title="Contacto" className="font-raleway text-blue font-bold uppercase text-sm mt-8 bottom-0 flex justify-start hover:text-yellow about-arrow" href="">Conoce Más</AnchorLink>
                </article>
                <article data-sal="fade-in" data-sal-delay="300" data-sal-easing="ease" className="px-8 py-8 bg-white lg:ml-4 my-4 lg:my-0 lg:mr-4 lg:w-1/3 flex flex-col shadow-lg  mb-10 lg:mb-0 justify-between">
                    <div>
                        <Img className="w-20" fluid={capitalRiesgo} alt="Capital Riesgo"/>
                        <h3 className="mt-2 text-blue font-raleway font-bold text-xl">Capital Riesgo y Capital Privado</h3>
                        <p className="text-gray mt-4 font-lato">Haz crecer tu negocio.</p>
                        <p className="text-gray mt-4 font-lato text-yellow font-bold">¿Tienes una start-up pero no tienes claridad para medir y evaluar la evolución de tu emprendimiento y quieres levantar capital?</p>
                        <p className="text-gray mt-4 font-lato">En Bansó te apoyamos a desarrollar un plan de crecimiento para que puedas alcanzar tu potencial.</p>
                        <p className="text-gray mt-4 font-lato">Te acompañamos de manera integral durante las negociaciones con potenciales inversionistas.</p>
                    </div>
                    <AnchorLink to="/#contacto" stripHash title="Contacto" className="font-raleway text-blue font-bold uppercase text-sm mt-8 bottom-0 flex justify-start hover:text-yellow about-arrow" href=""> Conoce Más</AnchorLink>
                </article>
                <article data-sal="fade-in" data-sal-delay="600" data-sal-easing="ease" className="px-8 py-8 bg-white lg:ml-4 lg:w-1/3 lg:flex flex-col justify-between shadow-lg mb-10 lg:mb-0">
                    <div>
                        <Img className="w-20" fixed={acompanamientoFinanciero} alt="Capital Semilla"/>
                        <h3 className="text-blue font-raleway font-bold text-xl">Acompañamiento Financiero Continuo</h3>
                        <p className="text-gray mt-4 font-lato">Haz eficiente y rentable tu negocio.</p>
                        <p className="text-gray mt-4 font-lato text-yellow font-bold">¿Tienes una empresa consolidada, pero sientes excesiva complejidad en la operación?</p>
                        <p className="text-gray mt-4 font-lato">En Bansó te apoyamos de forma permanente en la planeación financiera y estratégica para direccionar el crecimiento y desempeño de tu empresa.</p>
                        <p className="text-gray mt-4 font-lato">Te ayudamos con el desarrollo de proyecciones y análisis financiero, la definición y análisis de KPI´s, así como en el control presupuestal y de flujo de efectivo.</p>
                    </div>
                    <AnchorLink to="/#contacto" stripHash title="Contacto" className="font-raleway text-blue font-bold uppercase text-sm mt-8 bottom-0 flex justify-start hover:text-yellow about-arrow" href=""> Conoce Más</AnchorLink>
                </article>
            </div>
            <div className="lg:px-20 px-4 mt-10 pb-10 lg:pb-0">
                <div className="lg:flex justify-between">
                    <article data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-cubic"  className="px-8 py-8 lg:mr-4 bg-white lg:w-2/3 lg:flex lg:flex-col shadow-lg justify-between mb-20">
                        <div>
                            <Img className="w-20" fixed={contableFiscal} alt="Acompañamiento Contable Fiscal"/>
                            <h3 className=" text-blue font-raleway font-bold text-xl">Acompañamiento Contable y Fiscal</h3>
                            <p className="text-gray mt-4 font-lato">Mantén actualizado tu negocio.</p>
                            <p className="text-gray mt-4 font-lato  text-yellow font-bold">¿Tienes una empresa consolidada, pero tienes diversas necesidades?</p>
                            <p className="text-gray mt-4 font-lato">En Bansó nos adherimos al equipo de nuestros clientes y juntos anticipamos las oportunidades y riesgos inherentes en la operación del negocio, a través de diversos servicios:</p>
                            <ul className="text-gray mt-8 font-lato leading-7 pb-10 bullet-list">
                                <li>Asesoría fiscal continua: te mantenemos al tanto de actualizaciones fiscales.</li>
                                <li>Cumplimiento y Self-Check: te apoyamos en la revisión de tus obligaciones contables y fiscales, en la elaboración de estados financieros, en la revisión y/o elaboración de declaraciones mensuales y anuales, así como en la identificación y mitigación de riesgos.</li>
                                <li>Te brindamos acompañamiento en la obtención de devoluciones de saldos a favor.</li>
                                <li>Te apoyamos en due diligence fiscal y contable.</li>
                            </ul>
                        </div>
                        <AnchorLink to="/#contacto" stripHash title="Contacto" className="font-raleway text-blue font-bold uppercase text-sm bottom-0 flex justify-start hover:text-yellow about-arrow" href="">Conoce Más</AnchorLink>
                    </article>
                    <div data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-cubic" className="flex mb-4 lg:mb-0 flex-col justify-center items-center lg:w-1/3">
                        <Img fixed={bansoIcon} alt="Banso Icon"/>
                        <AnchorLink to="/#contacto" className="bg-yellow text-white uppercase font-raleway font-bold rounded px-6 mt-10 py-3 uppercase rounded-lg">Contáctanos</AnchorLink>
                    </div>
                </div>

            </div>
        </div>
    </section>);
};

export default Servicios;
