import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/sections/Home";
import Nosotros from "../components/sections/Nosotros";
import Servicios from "../components/sections/Servicios";
import Equipo from "../components/sections/Equiipo";
import Contacto from "../components/sections/Contacto";
import { ParallaxProvider } from 'react-scroll-parallax';
import Helmet from 'react-helmet'

const IndexPage = () => (
    <ParallaxProvider>
        <Layout>
            <SEO title="Inicio" />
            <Home/>
            <Nosotros/>
            <Servicios/>
            <Equipo/>
            <Contacto/>
        </Layout>
    </ParallaxProvider>
)

export default IndexPage
