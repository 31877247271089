import React, {useState} from "react"
import SiteTitle from "../SiteTitle";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import SiteSubtitle from "../SiteSubtitle";

const Contacto = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        let form = new FormData();
        form.append('name', name);
        form.append('email', email);
        form.append('company', company);
        form.append('phone', phone);
        form.append('message', message);

        await fetch('/send.php', {
            method: 'post',
            body: form,
        });
        setSent(true);
    };

    const data = useStaticQuery(
        graphql`
            query {
                phone: file(relativePath: { eq: "phone.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                mail: file(relativePath: { eq: "mail.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                ubicacion: file(relativePath: { eq: "ubicacion.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }`
        );

    return (<section id="contacto">
        <div className="lg:px-20 lg:mt-20 mt-10 lg:mb-20 mb-10 px-4 pt-14">
            <SiteTitle title="contacto"/>
            <SiteSubtitle subtitle="Queremos conocerte"/>
        </div>
        <div className="lg:flex justify-around items-start lg:px-20 px-4">
            <div className="lg:w-1/3">
                <div>
                    <div className="flex justify-start items-center">
                        <Img className="w-5" fluid={data.whatsapp.childImageSharp.fluid} alt="whatsapp"/>
                        <p className="text-blue ml-4 font-bold text-xl font-raleway">Whatsapp</p>
                    </div>
                    <a href="https://wa.me/+525584622393" className="mt-2 text-gray font-lato text-lg mt-4 block">+ 55 84 62 23 93</a>
                </div>
                <div className="mt-8">
                    <div className="flex justify-start items-center">
                        <Img className="w-5" fluid={data.phone.childImageSharp.fluid} alt="whatsapp"/>
                        <p className="text-blue ml-4 font-bold text-xl font-raleway">¿Quieres contactarnos?</p>
                    </div>
                    <a href="tel:5584622393" className="text-gray font-lato text-lg mt-4 block">+ 55 84 62 23 93</a>
                </div>
                <div className="mt-8">
                    <div className="flex justify-start items-center">
                        <Img className="w-5" fluid={data.mail.childImageSharp.fluid} alt="whatsapp"/>
                        <p className="text-blue ml-4 font-bold text-xl font-raleway">¿Qué tienes en mente?</p>
                    </div>
                    <a href="mailto:info@banso.mx" className="text-gray font-lato text-lg mt-4 block">info@banso.mx</a>
                </div>
                <div className="mt-8 mb-8 lg-mb-0">
                    <div className="flex justify-start items-center">
                        <Img className="w-5" fluid={data.ubicacion.childImageSharp.fluid} alt="whatsapp"/>
                        <p className="text-blue ml-4 mb-4 lg-mb-0 font-bold text-xl font-raleway">Ciudad de México.</p>
                    </div>
                </div>
            </div>
            {
                !sent ?
                    (<form  className="lg:w-2/3">
                        <h4 className="text-yellow font-bold font-raleway text-3xl">Escríbenos</h4>
                        <p className="text-gray font-lato text-lg">Programa una sesión de asesoría  y  juntos hagamos crecer tu empresa.</p>
                        <div className="flex justify-around mt-8 items-start w-full">
                            <div className="w-1/2 mr-4 xl:px-10 px-2 py-4 bg-gray-100 flex flex-col lg:flex-row justify-around items-start">
                                <label className="lg:w-1/4 text-gray font-lato" htmlFor="name">Nombre</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} className="lg:w-3/4 w-full text-gray font-lato outline-none bg-gray-100" type="text" id='name' name='name'/>
                            </div>
                            <div className="w-1/2 xl:px-10 px-2 py-4 bg-gray-100 flex flex-col lg:flex-row justify-around items-start">
                                <label className="lg:w-1/4 w-1/4 text-gray font-lato" htmlFor="phone">Teléfono</label>
                                <input value={phone} onChange={(e) => setPhone(e.target.value)} className="lg:w-3/4 w-full text-gray font-lato outline-none bg-gray-100" type="phone" id='phone' name='phone'/>
                            </div>
                        </div>
                        <div className="flex justify-around mt-4 items-start w-full">
                            <div className="w-1/2 mr-4 xl:px-10 px-2 py-4 bg-gray-100 flex flex-col lg:flex-row justify-around items-start">
                                <label className="lg:w-1/4 text-gray font-lato"  htmlFor="email">Correo</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className="lg:w-3/4 w-full text-gray font-lato outline-none bg-gray-100" type="email" id="email" name='email'/>
                            </div>
                            <div className="w-1/2 xl:px-10 px-2 py-4 bg-gray-100 flex flex-col lg:flex-row justify-around items-start">
                                <label className="lg:w-4/12 text-gray font-lato"  htmlFor="company">Compañía</label>
                                <input value={company} onChange={(e) => setCompany(e.target.value)} className="lg:w-3/4 w-full text-gray font-lato outline-none bg-gray-100" type="text" id="company" name='company'/>
                            </div>
                        </div>
                        <div className="w-full mr-4 xl:px-10 px-2 py-4 bg-gray-100 flex justify-around items-start flex-col mt-4">
                            <label className="lg:w-1/4 text-gray font-lato" htmlFor="message">Mensaje</label>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="w-full text-gray resize-none font-lato outline-none bg-gray-100" name="message" id="message" cols="30" rows="10"/>
                        </div>
                        <button type="form" onClick={handleSubmit} className="hover:bg-blue hover:text-white hover:border-blue border-yellow text-yellow border-2 py-4 px-14 rounded mt-4 font-bold font-lato uppercase">Enviar</button>
                    </form>) :
                    (<p/>)
            }
        </div>
    </section>);
};

export default Contacto;
