import React, {useState} from "react"
import SiteTitle from "../SiteTitle";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import CountUp from 'react-countup';
import SiteSubtitle from "../SiteSubtitle";

const Nosotros = () => {

    const [toggles, setToggles] = useState([false, false, false]);
    const data = useStaticQuery(
        graphql`
            query {
                arrow: file(relativePath: { eq: "arrow.png" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        `
    )

    const handleToggle = (index, value) => {
        let local = [...toggles];
        local = local.map(val => false)
        local[index] = value;
        setToggles(local);
    }

    return (<section id="nosotros" className="w-full z-10 bg-white text-center block relative">
        <div className="lg:pb-20 pb-0 pt-10 lg:px-20 px-2">
            <div className="block pt-0 lg:pt-20">
                <SiteTitle title="¿QUIÉNES SOMOS?"/>
            </div>
            <h4 className="mt-4 text-blue text-2xl lg:text-3xl font-raleway font-bold"><strong>Bansō 伴奏</strong> significa en japonés: <strong className="italic">acompañamiento.</strong></h4>
            <p className="text-gray font-lato lg:text-2xl text-lg my-10">Somos un <strong>laboratorio financiero y fiscal</strong>, conformado por <strong>expertos</strong> que generan <strong>soluciones integrales</strong><br/> y de <strong>gran impacto</strong> para nuestros clientes.</p>
            <p className="text-gray font-lato lg:text-2xl text-lg my-10">Trazamos contigo la <strong>estrategia correcta</strong> para el crecimiento sólido de tu empresa y te <strong>acompañamos</strong> en el camino.</p>
        </div>
        <div className="bg-gray-200 w-full px-20 md:px-0">
            <ul className="font-raleway font-black text-blue lg:flex lg:text-xs py-10 justify-between w-full lg:text-center">
                <li data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" className="cursor-pointer  hover:text-gray text-center block relative font-lato font-black">
                    <Img className="w-8 mx-auto block my-2" fluid={data.arrow.childImageSharp.fluid} alt="Arrow"/>
                    <h3 className="text-4xl my-4">+<CountUp start={0} delay={1} duration={2} end={3000} /> MDP</h3>
                    <p className="text-lg mt-2 lg:w-2/3 block mx-auto">en proyectos coordinados de levantamiento de capital.</p>
                </li>
                <li data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" className="cursor-pointer hover:text-gray text-center block relative font-lato font-black">
                    <Img className="w-8 mx-auto block my-2" fluid={data.arrow.childImageSharp.fluid} alt="Arrow"/>
                    <h3 className="text-4xl my-4">+ <CountUp start={0} delay={2} duration={5} end={30} /> AÑOS</h3>
                    <p className="text-lg mt-2 lg:w-2/3 block mx-auto">de experiencia acumulada en consultoría.</p>
                </li>
                <li data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" className="cursor-pointer hover:text-gray md:ml-4 text-center block relative font-lato font-black">
                    <Img className="w-8 mx-auto block my-2" fluid={data.arrow.childImageSharp.fluid} alt="Arrow"/>
                    <h3 className="text-4xl my-4"><CountUp start={0} delay={3} duration={5} end={100} />%</h3>
                    <p className="text-lg mt-2 lg:w-2/3 block mx-auto">satisfacción de nuestros clientes.</p>
                </li>
                <li data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" className="cursor-pointer hover:text-gray text-center block relative font-lato font-black">
                    <Img className="w-8 mx-auto block my-2" fluid={data.arrow.childImageSharp.fluid} alt="Arrow"/>
                    <h3 className="text-4xl my-4"><CountUp start={0} delay={4} duration={5} end={30} />%</h3>
                    <p className="text-lg mt-2 lg:w-2/3 block mx-auto">de rentabilidad promedio en proyectos asesorados.</p>
                </li>
            </ul>
        </div>
        <div className="lg:px-20 px-4 mt-20">
            <div className="lg:flex justify-between items-center pb-20">
                <div className="lg:w-1/4 text-center lg:text-left my-12 lg:my-0" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-out-cubic">
                    <SiteTitle title="¿CÓMO LO HACEMOS?"/>
                    <SiteSubtitle subtitle={`Garantizamos <br/> un proceso de <br/>trabajo eficiente`}/>
                </div>
                <div className="lg:w-3/4 lg:flex justify-between items-center" >
                    {
                        !toggles[0] ? (
                                <div
                                    role="button"
                                    tabIndex={1}
                                    onMouseEnter={() => {handleToggle(0, true)}}
                                    onMouseLeave={() => {handleToggle(0, false)}}
                                    className="cursor-pointer lg:w-1/3 py-52 outline-none bg-blue flex relative justify-center items-center">
                                    <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">01</span>
                                    <h3 className="text-white font-raleway lg:my-1 text-center font-bold uppercase lg:text-xl text-2xl">Diagnóstico del <br/> negocio</h3>
                                    <p/>
                                </div>
                        ) : (
                            <div
                                role="button"
                                tabIndex={2}
                                onMouseLeave={() => {handleToggle(0, false)}}
                                className="cursor-pointer lg:w-1/3 outline-none max-h-64 py-60 bg-blue flex relative justify-start px-10 items-center">
                                <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">01</span>
                                <div>
                                    <h3 className="text-white font-raleway text-left font-bold lg:text-xl text-2xl">Diagnóstico del <br/> negocio</h3>
                                    <p className="font-lato mt-4 text-white text-left text-lg">Analizamos y evaluamos la estructura financiera y fiscal de la empresa para tener visibilidad y proporcionar recomendaciones concretas.</p>
                                </div>
                            </div>
                        )
                    }
                    {
                        !toggles[1] ? (
                            <div
                                role="button"
                                tabIndex={3}
                                onMouseEnter={() => {handleToggle(1, true)}}
                                onMouseLeave={() => {handleToggle(1, false)}}
                                className="cursor-pointer lg:w-1/3  outline-none max-h-64 py-60 bg-gray flex relative justify-center items-center">
                                    <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">02</span>
                                    <h3 className="text-white font-raleway text-center font-bold uppercase lg:text-xl text-2xl">Planificación <br/> empresarial</h3>
                            </div>
                        ) : (
                            <div
                                role="button"
                                tabIndex={4}
                                onMouseLeave={() => {handleToggle(1, false)}}
                                className="cursor-pointer lg:w-1/3  outline-none max-h-64 py-60 bg-gray flex relative justify-start px-10 items-center">
                                    <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">02</span>
                                    <div>
                                        <h3 className="text-white font-raleway text-left font-bold lg:text-xl text-2xl">Planificación <br/> empresarial</h3>
                                        <p className="font-lato mt-4 text-white text-left text-lg">Ayudamos a las empresas a crear, desarrollar e implementar un plan de acción enfocado a los objetivos del negocio.</p>
                                    </div>
                            </div>
                        )
                    }
                    {
                        !toggles[2] ? (
                            <div
                                role="button"
                                tabIndex={5}
                                onMouseEnter={() => {handleToggle(2, true)}}
                                onMouseLeave={() => {handleToggle(2, false)}}
                                className="cursor-pointer lg:w-1/3  outline-none max-h-64 py-60 bg-yellow flex relative justify-center items-center">
                                    <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">03</span>
                                    <h3 className="text-white font-raleway text-center font-bold uppercase lg:text-xl text-2xl">Monitoreo del <br/> proceso</h3>
                            </div>
                        ) : (
                            <div
                                role="button"
                                tabIndex={6}
                                onMouseLeave={() => {handleToggle(2, false)}}
                                className="cursor-pointer lg:w-1/3 max-h-64  outline-none py-60 bg-yellow flex relative justify-start px-10 items-center">
                                    <span className="absolute top-0 right-0 large-numbers font-raleway font-bold opacity-5">03</span>
                                    <div>
                                        <h3 className="text-white font-raleway text-left font-bold lg:text-xl text-2xl">Monitoreo <br/> del proceso</h3>
                                        <p className="font-lato mt-4 text-white text-left text-lg">Brindamos seguimiento a los resultados obtenidos y ajustes en la ejecución del plan de trabajo.</p>
                                    </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </section>);
};

export default Nosotros;
